<template>
  <v-form
    ref="form"
  >
  <v-file-input
    truncate-length="15" 
    accept="image/*" 
    show-size 
    prepend-icon="mdi-camera" 
    placeholder="Pick an avatar" 
    label="Avatar" 
    :rules="rules" 
    @change="onAvatarChange" 
    :loading="avatarLoading"/>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import store from "@/store";

import { REFRESH_USER_PROFILE, SUBMIT_AVATAR_PICTURE } from "@/store/actions.type";
import { BEGIN_LOADING, END_LOADING } from "@/store/mutations.type";

export default Vue.extend({
  name: "EditProfile",
  computed: {
    ...mapGetters([
      "isLoading",
      "user_profile",
    ]),
  },
  components: {},
  created() {
    store.commit(BEGIN_LOADING);
    Promise.all([store.dispatch(REFRESH_USER_PROFILE, this.user_profile.id)]).then(() => {
      store.commit(END_LOADING);
    });
  },
  methods: {
    onAvatarChange: function(file) {
      const formData = new FormData();
      formData.append('fileUpload', file);
      this.avatarLoading = true;
      store.dispatch(SUBMIT_AVATAR_PICTURE, formData)
        .then(() => {
          this.avatarLoading = false;
        });
    }
  },
  data: () => ({
      rules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      avatarLoading: false
    }),
});
</script>